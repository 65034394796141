<template>
	<transition name="slide-fade" mode="out-in">
		<Layout>
			<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
				<form class="auth__form" @submit.prevent="handleSubmit(resetPassword)">
					<Logo />
					<div class="mb-5 pb-3 text-center">
						<h1 class="title is-size-4 is-semibold m-0">Esqueceu a senha?</h1>
						<!-- prettier-ignore -->
						<p>Digite <strong>seu e-mail</strong> e crie uma nova.</p>
					</div>

					<InputWithValidation tab="1" class="mb-5" rules="required|email" type="email" name="email" label="E-mail" v-model="auth.email">
						<router-link :to="{ name: 'Login' }" class="auth__forgot">Lembrou sua senha?</router-link>
					</InputWithValidation>

					<span class="is-block text-center">
						<b-button tabindex="2" native-type="submit" class="button is-button is-secondary" :loading="loading">Enviar</b-button>
					</span>
				</form>
			</ValidationObserver>
		</Layout>
	</transition>
</template>

<script>
import Layout from '@/layouts/Auth'
import Logo from '@/components/Logo'
import InputWithValidation from '@/components/inputs/InputWithValidation'
import { ValidationObserver } from 'vee-validate'
import { ToastProgrammatic as Toast } from 'buefy'
import Api from '@/services/api'

export default {
	components: {
		Layout,
		Logo,
		InputWithValidation,
		ValidationObserver
	},
	data() {
		return {
			loading: false,
			auth: {
				email: ''
			}
		}
	},
	methods: {
		async resetPassword() {
			try {
				this.loading = true
				const response = await Api.post('user/email', this.auth)
				const { status } = response
				if (status === 200) {
					const { message } = response.data
					Toast.open({
						message,
						type: 'is-success',
						position: 'is-bottom-right'
					})
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					Toast.open({
						message,
						type: 'is-danger',
						position: 'is-bottom'
					})
				}
			} finally {
				this.loading = false
			}
		}
	}
}
</script>
