<template>
	<section class="auth">
		<div class="columns"> 
			<div v-if="!isMobile" class="column is-two-fifths is-hidden-mobile p-0">
				<div class="auth__image" :class="{ 'animated': animate }">
					<b-image v-if="type == 'image'" :src="image" :placeholder="image" loading="lazy" ratio="2by3"></b-image>
					<video v-else :src="video" autoplay muted></video>
				</div>
			</div> 
			<div class="column auth__content">
				<slot>
					<router-view />
				</slot>
			</div>
		</div>
	</section>
</template>

<script>
import image from '../assets/images/background/login.jpg'
import video from '../assets/videos/login.mp4'
import eventHub from '@/services/eventHub'
import { responsive } from '@/mixins/responsive'
 
export default {
	name: 'Layout',
	mixins: [responsive],
	data() {
		return {
			animate: false,
			type: 'image',
			image: image,
			video: video
		}
	},
	mounted() {
		eventHub.$on('animate-login', () => {
			this.animate = true
		})
	}
}
</script>
