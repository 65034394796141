<template>
	<h1 class="logo text-center" v-bind="$attrs">
		<img src="@/assets/images/logo-dark.svg" :width="width" :alt="title" />
	</h1>
</template>

<script>
export default {
	name: 'Logo',
	props: {
		width: {
			type: String,
			default: '60px'
		},
		title: {
			type: String,
			default: 'Podash'
		}
	}
}
</script>
